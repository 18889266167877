<template>
  <div class="pageIntro">
    <h2>Seeking:</h2>
    <h3>Intership / Full Time employement</h3>
  </div>

  <div class="contactInfo">
    <div class="banner">
      <div v-for="pic in pics" :key="pic.imgFile" class="photoBox">
        <polaroid-pic :imgFile="pic.imgFile"></polaroid-pic>
      </div>
    </div>

    <div class="myInfo">
      <p class="emph">Jennifer Baldwin</p>
      <div class="row">
        <span>email:</span>
        <p>jbldwn@iupui.edu</p>
      </div>
      <div class="row">
        <span>phone:</span>
        <p>(317) 455-5237</p>
      </div>
      <div class="row">
        <span>exp grad:</span>
        <p>Summer 2024</p>
      </div>
      <div class="row last">
        <span>social:</span>
        <div class="smLinks">
          <a href="https://github.com/jbldwn/" target="_blank" class="eLink">
            <font-awesome-icon class="logo first" icon="fa-brands fa-github" />
          </a>
          <a
            href="https://www.linkedin.com/in/jennifer-baldwin-webdev/"
            target="_blank"
            class="eLink"
          >
            <font-awesome-icon class="logo" icon="fa-brands fa-linkedin" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pics: [
        {
          imgFile: "portfolio.jpg",
        },
        // {
        //     imgFile: "about.jpg",
        // },
        // {
        //     imgFile: "me.jpg",
        // }
      ],
    };
  },
};
</script>
<style scoped>
.banner {
  width: 30%;
  display: flex;
  justify-content: center;
}

.contactInfo {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.myInfo {
  font-size: 20px;
  max-width: 50%;
  width: 50%;
  min-width: fit-content;

  text-transform: capitalize;
  font-family: sans-serif;

  background-color: rgba(240, 255, 255, 0.3);
  border-radius: 30px;
  padding: 25px;
}
.myInfo:hover {
  background: linear-gradient(
    336deg,
    rgba(240, 255, 255, 0.45),
    rgba(240, 255, 255, 0.25),
    rgba(240, 255, 255, 0.1) 70.71%
  );
}
.myInfo:hover .logo {
  filter: drop-shadow(0 0 10px rgb(240, 240, 255));
  color: rgba(240, 240, 255, 0.8);
}
.row {
  margin: 2px auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 5px; */
}
.logo {
  height: 35px;
  color: rgba(0, 0, 0, 0.7);
}
.eLink:hover .logo {
  color: rgb(240, 240, 255);
  filter: drop-shadow(1px 2px 4px rgb(51, 51, 51));
}
.eLink {
  margin: -75px;
  padding: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.smLinks {
  min-width: 45%;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.last {
  margin-top: 4px;
}
.emph {
  font-size: 28px;
  margin-bottom: 10px;
  padding: 0 5px;
}
span {
  align-self: flex-start;
  text-transform: lowercase;
  font-family: "typeBld";
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
p {
  font-size: 16px;
  align-self: center;
}
</style>
