<template style="position: relative">
  <div class="background">
    <NavMenu @updateTitle="updateTitle" />
    <div class="content mainBorder">
      <div class="titleHolder">
        <h1 id="pageTitle">{{ pTitle }}</h1>
      </div>
      <div id="homeContent" class="contentHolder">
        <router-view @updateTitle="updateTitle" />
      </div>
    </div>
  </div>
</template>

<script>
import NavMenu from "./components/NavMenu.vue";

export default {
  name: "App",
  components: {
    NavMenu,
  },
  data() {
    return {
      pTitle: "home",
    };
  },
  methods: {
    updateTitle(pageTitle) {
      console.log("old Title: ", this.pTitle);
      this.pTitle = pageTitle;
      console.log("new Title: ", this.pTitle);
    },
  },
};
</script>

<style>
/* fonts */
@font-face {
  font-family: "besterReg";
  src: url(./assets/fonts/BestermindRegular.ttf);
}
@font-face {
  font-family: "typeReg";
  src: url(./assets/fonts/typewcond_regular.otf);
}
@font-face {
  font-family: "typeBld";
  src: url(./assets/fonts/typewcond_bold.otf);
}
@font-face {
  font-family: "typeDmi";
  src: url(./assets/fonts/typewcond_demi.otf);
}
/* base set */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: solid red 1px; */
}
/* built app */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
}
/* content inected to app */
/* background */
.background {
  /* style */
  min-height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
      45deg,
      rgba(205, 92, 92, 0.7),
      rgba(169, 92, 205, 0.5),
      rgba(205, 160, 92, 0.9)
    ),
    url(./assets/images/background.jpg);
  background-position: top;
  background-repeat: none;
  background-size: cover;

  /* organization */
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: flex-end;
  /* margin-left: auto; */
}
/* changeable content */
.content {
  width: 65%;
  height: 100%;
  /* min-height: 100vh; */
}
/* pageTitle */
.titleHolder {
  /* size and margin*/
  margin-left: 5%;

  /* font and casing */
  font-size: 40px;
  font-family: "besterReg";
  text-transform: capitalize;

  /* alignment and placement */
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  position: relative;
}
#pageTitle {
  position: absolute;
  top: -30px;
  left: 0;
}
/* bulk content */
.contentHolder {
  position: relative;
  margin-top: 20vh;
  width: 80%;
  min-height: calc(100% - 20vh);

  background: linear-gradient(
      217deg,
      rgba(65, 29, 29, 0.7),
      rgba(255, 0, 0, 0.05) 70.71%
    ),
    linear-gradient(
      127deg,
      rgba(65, 29, 29, 0.75),
      rgba(0, 255, 0, 0.05) 70.71%
    ),
    linear-gradient(336deg, rgba(65, 29, 29, 0.8), rgba(0, 0, 255, 0.05) 70.71%);
  border-radius: 30px;
  padding: 35px;

  /* flex */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
/* content header */
.pageIntro {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.pageIntro h2 {
  font-size: 36px;
}
.pageIntro h3 {
  font-family: "typeBld";
  margin-top: -10px;
  margin-left: 10%;
  font-size: 24px;
}
</style>
