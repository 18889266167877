<template>
  <div class="pageIntro">
    <h2>Hello World!</h2>
    <h3>my name is:</h3>
  </div>
  <span class="name">Jennifer</span>
  <!-- create an on hover display to say: -->
  <!-- Prefers: Jen -->

  <div class="bottom">
    <div class="glimpse">
      <div v-for="pic in pics" :key="pic.imgFile" class="photoBox">
        <polaroid-pic :imgFile="pic.imgFile"></polaroid-pic>
      </div>
    </div>
    <div class="intro">
      <!-- create an on hover display to: -->
      <!-- summarize each paragraph -->
      <p>
        It took me a while to find my professional niche. I have always had a
        deep love of logic puzzles, and I could find patterns and puzzle pieces
        in my work that kept me nearly satiated. I also have a deep appreciation
        of art, and a drive to be creative.
      </p>
      <!-- non traditional student -->
      <!-- likes logic puzzles (this sudoku) -->
      <!-- driven to be creative -->
      <p>
        In my professional settings, I was always let down by the lack of
        creative opportunity available. Web development is the perfect merriment
        between puzzle and art. Being able to breathe life into a design allows
        me to bring attention to detail that can really highlight the level of
        care and effort that goes into any website.
      </p>
      <!-- had several careers -->
      <!-- Loves the intersection of art and Web -->
      <!-- (Matches creative drive) -->
      <p class="last">
        Web developer by day, artist by night, I am a coffee-loving-transplant
        that was born and raised in Seattle and is currently residing in the
        Midwest. I live Fountain Square with my husband, dog, and two cats. When
        the weather is nice, we can be found outside doing anything and
        everything with our dog.
      </p>
      <!-- does watercolor in spare time -->
      <!-- loves anything artsy, coffee, animals, and the outdoors -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pics: [
        {
          link: "about.jpg",
          imgFile: "about.jpg",
        },
        {
          link: "portfolio.jpg",
          imgFile: "me.jpg",
        },
        {
          link: "contact.jpg",
          imgFile: "pets.jpg",
        },
      ],
      paragraphs: [
        "It took me a while to find my professional niche. I have always had a deep love of logic puzzles, and I could find patterns and puzzle pieces in my work that kept me nearly satiated. I also have a deep appreciation of art, and a drive to be creative. ",

        "In my professional settings, I was always let down by the lack of creative opportunity available. Web development is the perfect merriment between puzzle and art. Being able to breathe life into a design allows me to bring attention to detail that can really highlight the level of care and effort that goes into any website.",

        "Web developer by day, artist by night, I am a coffee-loving-transplant that was born and raised in Seattle and is currently residing in the Midwest. I live Fountain Square with my husband, dog, and two cats. When the weather is nice, we can be found outside doing anything and everything with our dog.",
      ],
    };
  },
};
</script>
<style scoped>
.name {
  /* placement */
  position: absolute;
  left: 38%;
  right: auto;

  /* font */
  font-size: 68px;
  font-family: "besterReg";
  text-transform: capitalize;
  text-align: center;

  /* style */
  filter: drop-shadow(0 0 15px rgb(255, 255, 255));
}
#aboutContent {
  position: relative;
  margin-top: 15vh;

  /* looks different because of scroll bar */
  /* Consider looking up different scroll bar styling */

  /* margin-top: 18vh; */
}
.intro {
  width: 65%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
p {
  font-size: 16px;
  line-height: 30px;
  text-indent: 15%;
  margin-bottom: 30px;
  justify-content: space-between;
}
p:hover {
  /* create a summary for each paragraph to appear on hover */
}
.bottom {
  background-color: rgba(240, 255, 255, 0.25);
  box-shadow: 0 0 16px 0 rgba(85, 38, 38, 0.4);
  border-radius: 15px;
  padding: 30px 45px;

  min-height: 65vh;

  display: flex;
  justify-content: space-between;
}
.glimpse {
  width: 24%;
  margin: 5px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.last {
  margin-bottom: 0;
}
* {
  /* border: solid 1px red; */
}
</style>
