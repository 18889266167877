<template>
    
    <div class="polaroid">
        <div class="image">
            <img :src="require(`../assets/images/${this.imgFile}`)" />
            <div class="glare">

            </div>

        </div>
        <div class="bottom" v-if="this.caption">
            <p>{{ this.caption }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() { },
    props: {
        imgFile: String,
        caption: String,
    },
}

</script>

<style scoped>
.polaroid {
    background-color: rgb(240, 240, 255);
    width: 100%;
    max-height: 100%;
    border-radius: 5px;
    padding: 8%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.polaroid:hover{
    filter: drop-shadow(0px 0px 30px rgb(39, 17, 17));
}

#aboutContent .glimpse .photoBox .polaroid:hover{
    filter: none;
}

/* image container */
.image{
    width: 100%;
    position: relative;
    border-radius: 5px;
}
/* image and glare */
img{
    border-radius: 5px;
    max-width: 100%;
}
.glare{
    border-radius: 5px;

    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:2.75%;

    background: 
        linear-gradient(217deg, rgba(205,92,92,.45), rgba(205,0,0,0.05) 70.71%),
        linear-gradient(127deg, rgba(181, 205, 92, 0.4), rgba(0,205,0,0.05) 70.71%),
        linear-gradient(336deg, rgba(92, 169, 205, 0.5), rgba(0,0,205,0.05) 70.71%);
}

/* glare hover */
.polaroid:hover .image .glare{
    background: linear-gradient(217deg, rgba(205,92,92,.2), rgba(205,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(181, 205, 92, 0.25), rgba(0,205,0,0) 70.71%),
            linear-gradient(336deg, rgba(92, 169, 205, 0.3), rgba(0,0,205,0) 70.71%);
}
/* Script area */
.bottom{
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;

    padding:2% 0;
    
    font-family: 'besterReg';
    font-size: 2.5vw;
    font-weight: bold;
}

/* removes script area for about page */
#aboutContent .glimpse .photoBox .polaroid .bottom{
    font-size: 2vw;
    display: none;
}

/* removes glare over project hver */
.project .image .polaroid:hover{
    filter: none;
}
</style>