<template>
  <div class="pageIntro">
    <h2>Jennifer Baldwin</h2>
    <h3>Web Developer</h3>
  </div>
  <div class="directions">
    <router-link
      v-for="pic in pics"
      :key="pic.link"
      v-bind:to="`/${pic.link}`"
      @click="updateTitle(pic.link)"
      class="photoBox"
    >
      <polaroid-pic
        :imgFile="pic.imgFile"
        :caption="pic.caption"
      ></polaroid-pic>
    </router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pics: [
        {
          link: "about",
          imgFile: "about.jpg",
          caption: "My Bio",
        },
        {
          link: "portfolio",
          imgFile: "portfolio.jpg",
          caption: "See Projects",
        },
        {
          link: "contact",
          imgFile: "contact.jpg",
          caption: "Lets Talk",
        },
      ],
    };
  },
  emits: ["updateTitle"],
  props: {
    pTitle: String,
  },
  methods: {
    updateTitle(newTitle) {
      this.$emit("updateTitle", newTitle);
    },
  },
};
</script>
<style scoped>
.directions {
  margin-top: -15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.photoBox {
  width: 24%;
  text-decoration: none;
  color: black;
}
</style>
