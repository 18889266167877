<template>
  <div class="pageIntro">
    <h2>Jennifer Baldwin</h2>
    <h3>Web Developer</h3>
  </div>
  <div class="filter">
    <p>Filters:</p>
    <!-- still working on filter functionality -->
    <!-- add second click function to remove filter -->
    <!-- add function to check current matches for inclusion of addisional filter -->
    <!-- filtered results should return *only* projects containing *all* filters -->
    <div class="options">
      <div
        class="tag"
        v-for="tag in allTags"
        :key="tag"
        @click.prevent="filterProjects(tag)"
        :id="tag"
      >
        {{ tag }}
      </div>
    </div>
  </div>
  <div class="removeFilters" v-if="selectedTags.length > 0">
    <div class="remove" @click.prevent="clearFilters()">
      Remove Selected Filters
    </div>
  </div>
  <div class="projects">
    <div class="unfiltered" v-if="tagMatches.length === 0">
      <a
        v-for="project in projects"
        :key="project.link"
        class="projectBox"
        v-bind:href="project.link"
        target="_blank"
      >
        <project-preview
          :link="project.link"
          :imgFile="project.imgFile"
          :title="project.title"
          :synopsis="project.synopsis"
          :tags="project.tags"
        ></project-preview>
      </a>
    </div>
    <div class="filtered">
      <a
        v-for="match in tagMatches"
        :key="match.link"
        class="projectBox"
        v-bind:href="match.link"
        target="_blank"
      >
        <project-preview
          :link="match.link"
          :imgFile="match.imgFile"
          :title="match.title"
          :synopsis="match.synopsis"
          :tags="match.tags"
        ></project-preview>
      </a>
    </div>
    <div class="filter pTag siteSpec">
      <p>This site:</p>
      <div class="options siteSpec">
        <div class="tag" v-for="tag in thisProject" :key="tag">{{ tag }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      thisProject: ["css", "html", "JavaScript", "vue"],
      allTags: ["css", "scss", "html", "jQuery", "JavaScript", "php", "vue"],
      projects: [
        {
          title: "Keurig Spoof",
          synopsis:
            "For this final, I had to use Firebase's authentication and adding it to the project. The Final had to look like the Keurig site with an opperational shopping cart, log in and create an account page, and show at least 10 coffee machines.",
          imgFile: "keurig.jpg",
          link: "https://github.com/jbldwn/final/blob/main/README.md",
          tags: ["JavaScript", "css", "scss", "html", "jQuery"],
        },
        {
          title: "Pong{ish}",
          synopsis:
            "This was a midterm I had for an intro to JavaScript course. The goal was to create a ball and a paddle. I was having so much fun I made it more challenging by making the ball get faster, and the paddle get shorter.",
          imgFile: "pongish.jpg",
          link: "https://in-info-web4.informatics.iupui.edu/~jbldwn/pongish/Pongish/",
          tags: ["JavaScript", "css", "html"],
        },
        // {
        //     title: "Project Name",
        //     synopsis: "Brief project description",
        //     imgFile: "about.jpg",
        //     link: "a link to project git",
        //     tags:["JavaScript", "css", "scss", "html"]
        // },
        /* Tag testers */
      ],
      selectedTags: [],
      tagMatches: [],
    };
  },
  methods: {
    filterProjects(tagName) {
      // add 'selected' styling, and push to Array
      const selectedTag = document.getElementById(tagName);

      selectedTag.classList.add("selected");
      this.selectedTags.push(tagName);

      // filter by selected filters
      for (let i = 0; i < this.projects.length; i++) {
        const project = this.projects[i];
        const tags = project.tags.filter((tag) => tag === tagName);
        if (tags.length > 0) {
          if (this.tagMatches.filter((obj) => obj === project) == "") {
            this.tagMatches.push(project);
          }
        }
      }
    },
    clearFilters() {
      for (let i = 0; i < this.selectedTags.length; i++) {
        const tag = this.selectedTags[i];
        const el = document.getElementById(tag);
        el.classList.remove("selected");
      }

      this.selectedTags = [];
      this.tagMatches = [];
    },
  },
};
</script>
<style>
#PortfolioContent {
  min-height: 65vh;
}
/* Filters */
.removeFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-family: "typeBld";
}
.remove {
  margin-top: 15px;
  padding: 2px 10px 5px;
  border-radius: 5px;
  border: solid black 3px;
  background-color: rgba(240, 240, 255, 0.15);
}
.remove:hover {
  background-color: rgba(240, 240, 255, 0.4);
  cursor: pointer;
}
.filter {
  display: flex;
  font-family: "typeBld";
  font-size: 22px;
}
.filter p {
  margin-right: 20px;
  margin-left: auto;
  filter: drop-shadow(1px 2px 5px rgb(240, 240, 255));
}
.options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/* tags */
.siteSpec {
  margin-top: 15px;
  flex-wrap: nowrap;
  align-items: flex-end;
}
.tag {
  font-size: 12px;
  font-family: "typeBld";

  background-color: rgb(205, 92, 92);
  color: rgb(34, 34, 34);
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
  border: solid 3px rgb(205, 92, 92);
}
.selected {
  border: solid 3px rgb(205, 92, 92);
  color: rgba(240, 255, 255, 0.8);
  background-color: rgb(158, 70, 70);
}
.options div:hover {
  background-color: rgb(114, 58, 58);
  color: rgb(240, 240, 255);
  cursor: pointer;
  border: solid 3px rgb(77, 39, 39);
}

/* undo hover effect from filter */
.pTag .options div:hover {
  background-color: rgb(205, 92, 92);
  color: rgb(39, 39, 39);
  border: solid 3px rgb(205, 92, 92);
  cursor: default;
}
a {
  text-decoration: none;
  color: black;
}
/* Project holder */
.projects {
  margin-top: 15px;
}
</style>
