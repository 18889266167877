<template>
    <div class="project">
        <div class="image">
            <polaroid-pic :imgFile="this.imgFile"></polaroid-pic>
        </div>
        <div class="details">
            <h4 class="title">{{ this.title }}</h4>
            <p class="synopsis">{{ this.synopsis }}</p>
            <div class="options">
                <div class="tag" v-for="tag in tags" :key="tag">{{ tag }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() { },
    props: {
        title: String,
        synopsis: String,
        imgFile: String,
        link: String,
        tags: Array,
    },
}
</script>

<style>
.project{
    /* style */
    background-color: rgba(240, 255, 255,.25);
    box-shadow: 0 0 16px 0 rgba(85, 38, 38, 0.4);
    border-radius: 15px;

    /* organization */
    display: flex;
    justify-content: space-between;
    
    padding: 30px;
    margin-bottom: 15px;
}
.project:hover{
    background-color: rgba(240, 255, 255,.35);
    box-shadow: 0 0 35px 0 rgba(85, 38, 38, 0.8);
}
.image{
    width: 30%;
}
.details{
    /* size */
    width: 66%;
    
    /* organization */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.details .title{
    text-align: start;
    font-family: 'typeBld';
    font-size: 34px;
}
.details .synopsis{
    font-size: 11px;
    line-height: 22px;
    text-indent: 60px;
    margin: 15px 0 15px;
}
</style>