<template>
  <!-- <div id="doodle">
        interactive doodle coming soon
    </div> -->

  <nav>
    <div class="mainBorder navFlex">
      <a href="/" class="monogram">
        <div class="hRectangle mgline"></div>
        <div class="wRectangle mgline"></div>
        <div class="square mgline">
          <span class="unrotate" id="jay">J</span>
          <span class="unrotate" id="bee">B</span>
        </div>
      </a>
      <div class="links">
        <router-link to="/" class="link" @click="updateTitle('home')">
          <font-awesome-icon class="logo" icon="fa-solid fa-earth-americas" />
          <p class="page">Home</p>
        </router-link>
        <router-link to="/about" class="link" @click="updateTitle('about')">
          <font-awesome-icon class="logo" icon="fa-solid fa-route" />
          <p class="page">About</p>
        </router-link>
        <router-link
          to="/portfolio"
          class="link"
          @click="updateTitle('portfolio')"
        >
          <font-awesome-icon class="logo" icon="fa-solid fa-pen-ruler" />
          <p class="page">portfolio</p>
        </router-link>
        <router-link
          to="/contact"
          class="link last"
          @click="updateTitle('contact')"
        >
          <font-awesome-icon class="logo" icon="fa-solid fa-map-location-dot" />
          <p class="page">contact</p>
        </router-link>
      </div>
      <div class="miniContact">
        <p>Jennifer Baldwin</p>
        <p>jbldwn@iu.edu</p>
        <p class="boring">&copy;2024 All rights reserved.</p>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
  emits: ["updateTitle"],
  methods: {
    updateTitle(newTitle) {
      this.$emit("updateTitle", newTitle);
    },
  },
};
</script>

<style>
.mainBorder {
  min-height: 100vh;
  padding: 60px 0 40px;
}
/* interactive doodle */
#doodle {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 199;
  height: 100vh;
  width: 15%;
  background-color: rgb(206, 147, 165);
}
/* Nav styling */
nav {
  position: fixed;
  left: 15%;
  top: 0;
  z-index: 200;
  height: 100vh;

  background-image: linear-gradient(
      45deg,
      rgba(165, 133, 179, 0.7),
      rgba(165, 133, 179, 0.5),
      rgba(165, 133, 179, 0.9)
    ),
    url(../assets/images/navTexture.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  filter: drop-shadow(0px 0px 15px rgb(67, 48, 75));
  min-width: 180px;
  width: 15%;
}
.navFlex {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
}

/* Monogram */

.monogram {
  margin: 0 auto;
  height: 18vh;
  width: calc(18vh);

  position: relative;

  rotate: -45deg;
}
.mgline {
  border: solid #121b1b 1px;
  margin: 0 auto;
}
.hRectangle {
  position: absolute;
  top: -5%;
  left: 5%;
  height: 100%;
  width: 80%;
}
.wRectangle {
  position: absolute;
  left: -5%;
  top: 5%;
  width: 100%;
  height: 80%;
}
.square {
  position: absolute;

  width: 90%;
  height: 90%;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/* Letters */
.unrotate {
  font-size: 48px;
  font-family: "besterReg";
  text-decoration: none;
  color: #121b1b;
  rotate: 45deg;
  text-transform: capitalize;
}
#jay {
  position: absolute;
  right: 8%;
  left: 21%;
  top: 3%;
}
#bee {
  position: absolute;
  right: 36%;
  bottom: -15%;
}

/* links styling */
.links {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  flex-direction: column;
}
.link {
  width: 100%; /* for hover interaction */

  /* organization */
  display: flex;
  align-items: center;
  padding: 5px 0 5px 15%;
  margin-bottom: 15px; /* empty space between links */

  /* hyperlink reset */
  text-decoration: none;
  color: #121b1b;

  /* font styling */
  font-family: "typeReg";
  text-transform: capitalize;
  font-size: 24px;
}
.last {
  margin-bottom: 0;
}
.logo {
  margin-right: 15px;
}

/* link hover effect */
.link:hover .logo,
.link:hover .page,
.monogram:hover {
  font-family: "typeBld";
  filter: drop-shadow(1px 2px 4px rgb(67, 48, 75));
}

/* small contact and legalease */
.miniContact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: "typeBld";
  font-size: 20px;
}
.boring {
  font-family: sans-serif;
  font-size: 12px;
  margin-top: 15px;
}

/* active styling */
a.router-link-active {
  font-family: "typeBld";
  background-color: rgba(205, 160, 92, 0.5);
  color: rgb(77, 55, 22);
  filter: drop-shadow(0px 0px 4px rgb(67, 48, 75));
  cursor: default;
}
a.router-link-active .logo,
a.router-link-active .page {
  filter: none !important;
}
</style>
