/* routing */
import router from './router'


import { createApp } from 'vue'
import App from './App.vue'

import PolaroidPic from './components/PolaroidPic.vue'
import ProjectPreview from './components/ProjectPreview.vue'


/* fontAwesome imports */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faEarthAmericas, faRoute, faPenRuler, faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add( faEarthAmericas , faRoute, faPenRuler, faMapLocationDot, faGithub, faLinkedin)

const app = createApp(App);
app.component('polaroid-pic', PolaroidPic);
app.component('project-preview', ProjectPreview);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);

app.mount('#app');
